import { Middleware } from "@reduxjs/toolkit";
import { NotificationType, showNotification } from "../redux/reducers/notificationSlice";

export const axiosMiddleware: Middleware = ({ dispatch }) =>
    (next) =>
        async (action: any) => {

            if (action.type.endsWith("/rejected")) {
                console.log(action.type)
                const errorMessage = action.payload?.message;
                if (errorMessage) {
                    dispatch(
                        showNotification({
                            type: NotificationType.Error,
                            message: errorMessage,
                        })
                    );
                } else {
                    dispatch(
                        showNotification({
                            type: NotificationType.Error,
                            message: 'Sorry, something went wrong on our end. Please try again later.',
                        })
                    );
                }
            } else if (action.type.endsWith("/fulfilled")) {
                const successMessage = action.payload?.message;
                if (successMessage) {
                    dispatch(
                        showNotification({
                            type: NotificationType.Success,
                            message: successMessage,
                        })
                    );
                }
            }

            return next(action);
        };