
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILoginRequest } from '../../../pages/login';
import { GetApi, PostApi } from '../../../services/api-service';
import { AxiosError } from 'axios';
import { hideLoader, showLoader } from '../../reducers/loaderSlice';

export const getOPTLogin = createAsyncThunk("login/otp",
    async (payload: ILoginRequest, { rejectWithValue, dispatch }) => {
        try {
            dispatch(showLoader())
            const response = await PostApi(`/generateOTP`, payload);
            dispatch(hideLoader())
            return response.data;
        } catch (error) {
            dispatch(hideLoader())
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


export const getUserName = createAsyncThunk("interview/getName",
    // eslint-disable-next-line no-empty-pattern
    async (payload: any, { rejectWithValue, dispatch }) => {
        try {
            dispatch(showLoader())
            const response = await GetApi(`/getName`);
            dispatch(hideLoader())
            return response.data;
        } catch (error) {
            dispatch(hideLoader())
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

