import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getOPTLogin, getUserName } from "../actions/user";
import { AuthState, Status, User } from "../actions/user/iAuth";

const initialState: AuthState = {
  authenticated: false,
  loading: false,
  status: Status.NotLoggedIn,
  user: null
}

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.authenticated = true;
      state.status = Status.LoggedIn;
    },
    authStatus: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
      state.status = Status.LoggedIn;
    },
    logout: (state) => {
      sessionStorage.removeItem('accessToken')
      state.authenticated = false;
      state.user = null;
      state.status = Status.NotLoggedIn;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOPTLogin.pending, (state) => {
        state.status = Status.InProgress;
      })
      .addCase(getOPTLogin.fulfilled, (state, action: PayloadAction<User>) => {
        sessionStorage.setItem('accessToken', (action.payload.accessToken))
        state.status = Status.LoggedIn
        state.user = action.payload;
        state.authenticated = true;
      })
      .addCase(getOPTLogin.rejected, (state, action) => {
        state.status = Status.Failed;
        state.authenticated = false;
        // state.error = action.error.message;
      })

    builder
      .addCase(getUserName.pending, (state) => {

      })
      .addCase(getUserName.fulfilled, (state, action: PayloadAction<User>) => {
        const token = state.user?.accessToken;
        if (token)
          state.user = {
            accessToken: token,
            name: action.payload.name
          };
      })
      .addCase(getUserName.rejected, (state, action) => {
        console.log(action)
        // state.error = action.error.message;
      })
  },
});

export const { updateUser, authStatus, logout } = userSlice.actions;

export default userSlice.reducer;

