// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/reducer";
import { axiosMiddleware } from "../services/middleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(axiosMiddleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch