import { Box } from "@mui/material";
import "./Message.css";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { ISpeach } from "../../redux/reducers/chatSlice";

export const enum ServerUser {
  Server,
  User,
  None,
}

export interface IMessage {
  type: ServerUser;
  name: string;
  time: string;
  msg: string;
  blob?: Blob;
}

const Message: React.FC<{ msg: IMessage }> = ({ msg }) => {
  return (
    <>
      <Box
        sx={{
          marginTop: 2,
          fontSize: 14,
          maxWidth: "100%",
          textAlign: msg.type === ServerUser.Server ? "left" : "right",
          paddingLeft: msg.type === ServerUser.Server ? 1 : 10,
          paddingRight: msg.type === ServerUser.Server ? 10 : 2,
          textWrap: "wrap",
        }}
      >
        <div>
          <p style={{ marginTop: 1, marginBottom: 2 }}>
            <b>{msg.type === ServerUser.Server ? msg.name : "You"} &nbsp; </b>
            <span>{msg.time}</span>
          </p>
          <span
            style={{
              // background: msg.type === ServerUser.Server ? '#1E1F250F' : '#EFF6FF',
              background:
                msg.type === ServerUser.Server
                  ? "linear-gradient(90deg, rgba(18, 148, 242, 0.1) 0%,rgba(18, 148, 242, 0.05) 100% )"
                  : "linear-gradient(90deg,rgba(30, 155, 111, 0.1) 0%,rgba(30, 155, 111, 0.05) 100%)",
              padding: 5,
              borderRadius: 5,
              lineHeight: 1.5,
              display: "inline-block",
            }}
          >
            {msg.msg}
          </span>
        </div>
      </Box>
    </>
  );
};

export const LoadingMessage: React.FC = () => {
  const { speach } = useSelector((state: RootState) => state.chat);

  if (
    speach.status === ISpeach.GetAINextQuestion ||
    speach.status === ISpeach.SpeachInprogress
  ) {
    return (
      <>
        <Box
          sx={{
            marginTop: 2,
            fontSize: 14,
            maxWidth: "100%",
            textAlign:
              speach.botOrUser === ServerUser.Server ? "left" : "right",
            paddingLeft: speach.botOrUser === ServerUser.Server ? 1 : 10,
            paddingRight: speach.botOrUser === ServerUser.Server ? 10 : 2,
            textWrap: "wrap",
          }}
        >
          <div>
            <p style={{ marginTop: 1, marginBottom: 1 }}>
              <b>
                {speach.botOrUser === ServerUser.Server ? "Ann" : "You"} &nbsp;{" "}
              </b>
            </p>
            <div
              id="wave"
              style={{
                float:
                  speach.botOrUser === ServerUser.Server ? "left" : "right",
              }}
            >
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

export default Message;
