import React, { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { RouterLinks } from "../utilities/routes";
import { JSX } from "react/jsx-runtime";
import SuspenseLoader from "../components/SuspenseLoader";
import BaseLayout from "../layouts/BaseLayout";
import AuthLayout from "../layouts/AuthLayout";

const Loader = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(lazy(() => import("../pages/login")));
const WaitingRoom = Loader(lazy(() => import("../pages/waiting-room")));
const InterviewRoom = Loader(lazy(() => import("../pages/interview-room")));
const ThankYou = Loader(lazy(() => import("../pages/thank-you")));
const ThankYouWithError = Loader(
  lazy(() => import("../pages/thank-you-error"))
);

// Dashboards

const routes: RouteObject[] = [
  {
    path: RouterLinks.GLOBAL,
    element: <BaseLayout />,
    children: [
      {
        path: RouterLinks.DEFAULT,
        element: <Navigate to={RouterLinks.LOGIN} replace />,
      },
      {
        path: RouterLinks.LOGIN,
        element: <Login />,
      },
      {
        path: RouterLinks.LOGIN_NEW,
        element: <Login />,
      },
      {
        path: RouterLinks.WAITING_ROOM,
        element: (
          <AuthLayout>
            <WaitingRoom />
          </AuthLayout>
        ),
      },
      {
        path: RouterLinks.ROOM,
        element: (
          <AuthLayout>
            <InterviewRoom />
          </AuthLayout>
        ),
      },
      {
        path: RouterLinks.THANK_YOU,
        element: (
          <AuthLayout>
            <ThankYou />
          </AuthLayout>
        ),
      },
      {
        path: RouterLinks.THANK_YOU_WITH_ERROR,
        element: (
          <AuthLayout>
            <ThankYouWithError />
          </AuthLayout>
        ),
      },

      // {
      //     path: 'dashboard',
      //     element: (
      //         <SidebarLayout />
      //     ),
      //     children: [
      //         {
      //             path: '',
      //             element: <Crypto />
      //         }

      //     ]
      // },
      // {
      //     path: '*',
      //     element: <Status404 />
      // },
    ],
  },
];

export { routes };
