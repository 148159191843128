import { createSlice } from "@reduxjs/toolkit";

type WaitingRoom = {
    agreed: boolean;
};


const initialState: WaitingRoom = {
    agreed: false,
};

const waitingRoomSlice = createSlice({
    name: "waiting",
    initialState,
    reducers: {
        onChecked: (state) => {
            state.agreed = !state.agreed;
        },
    },
});

export const { onChecked } = waitingRoomSlice.actions;
export default waitingRoomSlice.reducer;