import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMessage, ServerUser } from "../../pages/interview-room/Message";
import moment from "moment";
import {
  getFeedback,
  getQuestion,
  getQuestionV2,
  sendAnswer,
} from "../actions/interview";

const INTERVIEW_SESSION_IN_MIN = Number(`${process.env.INTERVIEW_SESSION_MIN}`);

const INTERVIEW_MAX_IDLE_COUNT = Number(`${process.env.MAX_IDLE_COUNT}`) - 1;

export const enum ISessionStatus {
  InterviewNotStarted,
  InterviewInteruption,
  InterviewStarted,
  InterviewPaused,
  InterviewResumed,
  InterviewCompleted,
}

export const enum ISpeach {
  SpeachNotStarted,
  SpeachStarted,
  SpeachInprogress,
  SpeachSubmissionInprogress,
  SpeachSumittedForAIText,
  SpeachCompleted,
  GetAINextQuestion,
}

type IChat = {
  interviewStatus: ISessionStatus;
  interviewEndTime: number;
  interviewStartTime: number;
  timeLeft: string;
  isAISpeaking: boolean;
  loading: boolean;
  getFeedbackSubmission: boolean;
  interviewRecordSubmission: boolean;
  speach: {
    status: ISpeach;
    botOrUser: ServerUser;
  };
  aiQuestions: IMessage[];
  msgs: IMessage[];
  idleCount: number;
};

const initialState: IChat = {
  interviewStatus: ISessionStatus.InterviewNotStarted,
  interviewEndTime: 0,
  interviewStartTime: 0,
  timeLeft: "",
  isAISpeaking: false,
  loading: false,
  getFeedbackSubmission: false,
  interviewRecordSubmission: false,
  aiQuestions: [],
  speach: {
    botOrUser: ServerUser.None,
    status: ISpeach.SpeachNotStarted,
  },
  msgs: [],
  idleCount: 0,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    listeningStatus: (
      state,
      action: PayloadAction<{
        botOrUser: ServerUser;
        status: ISpeach;
      }>
    ) => {
      state.speach.botOrUser = action.payload.botOrUser;
      state.speach.status = action.payload.status;
    },
    sendChatMessage: (state, action: PayloadAction<IMessage>) => {
      state.msgs = [...state.msgs, { ...action.payload }];
    },
    startSession: (state) => {
      state.interviewStatus = ISessionStatus.InterviewStarted;
      state.interviewStartTime = moment().utc().unix();
      state.interviewEndTime = moment()
        .utc()
        .add(INTERVIEW_SESSION_IN_MIN, "minutes")
        .add(1, "seconds")
        .unix();
    },
    pauseSession: (state) => {
      state.interviewStatus = ISessionStatus.InterviewPaused;
    },
    resumeSession: (state) => {
      state.interviewStatus = ISessionStatus.InterviewResumed;
    },

    endSession: (state) => {
      // console.log("Calling end session");
      state.interviewStatus = ISessionStatus.InterviewCompleted;
    },
    updateTime: (state, action: PayloadAction<string>) => {
      state.timeLeft = action.payload;
    },
    isAISpeaking: (state, action: PayloadAction<boolean>) => {
      state.isAISpeaking = action.payload;
    },
    resetSession: (state) => {
      state.interviewStatus = ISessionStatus.InterviewNotStarted;
      // return { ...initialState }
    },
    uploadedVideo: (state) => {
      state.interviewRecordSubmission = true;
      // console.log("Submission updated to true");
      // return { ...initialState }
    },
    sessionInteruption: (state) => {
      state.interviewStatus = ISessionStatus.InterviewInteruption;
      // return { ...initialState }
    },
    updateIdleTimeForUserReply: (state) => {
      state.idleCount = state.idleCount + 1;
      if (state.idleCount <= INTERVIEW_MAX_IDLE_COUNT) {
        const msg: IMessage = {
          msg: "Hey, I am waiting for your response. If you need any clarifications, please let me know",
          // name: "Caterine",
          name: "Ann",
          type: ServerUser.Server,
          time: new Date().toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        };

        state.msgs.push(msg);
        state.aiQuestions.push(msg);
        state.speach.botOrUser = ServerUser.None;
        state.speach.status = ISpeach.SpeachCompleted;
      } else if (state.idleCount === INTERVIEW_MAX_IDLE_COUNT + 1) {
        const msg: IMessage = {
          msg: "Hey, there is a delay in your responses. Request you to respond promptly. Further delay or one more instance of delay could result in termination of our discussion",
          // name: "Caterine",
          name: "Ann",
          type: ServerUser.Server,
          time: new Date().toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        };

        state.msgs.push(msg);
        state.aiQuestions.push(msg);
        state.speach.botOrUser = ServerUser.None;
        state.speach.status = ISpeach.SpeachCompleted;
      } else if (state.idleCount === INTERVIEW_MAX_IDLE_COUNT + 2) {
        state.interviewStatus = ISessionStatus.InterviewCompleted;
        // const msg: IMessage = {
        //   msg: "You will logout in next one min if no response ?",
        //   // name: "Caterine",
        //   name: "Ann",
        //   type: ServerUser.Server,
        //   time: new Date().toLocaleString("en-US", {
        //     hour: "2-digit",
        //     minute: "2-digit",
        //     hour12: true,
        //   }),
        // };

        // state.msgs.push(msg);
        // state.aiQuestions.push(msg);
        // state.speach.botOrUser = ServerUser.None;
        // state.speach.status = ISpeach.SpeachCompleted;
      }
      // else if (state.idleCount === INTERVIEW_MAX_IDLE_COUNT + 3) {
      //   state.interviewStatus = ISessionStatus.InterviewCompleted;
      // }
      // return { ...initialState }
    },

    uploadAudioToAI: (state) => {
      state.speach.botOrUser = ServerUser.None;
      state.speach.status = ISpeach.SpeachSumittedForAIText;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestion.pending, (state) => {
        // state.loading = true;
        state.speach.botOrUser = ServerUser.Server;
        state.speach.status = ISpeach.SpeachInprogress;
      })
      .addCase(
        getQuestion.fulfilled,
        (
          state,
          action: PayloadAction<{ value: string; annotations: any[] }>
        ) => {
          // state.loading = true;
          let outputString = action.payload.value
            .replace("**Interviewer:**", "")
            .trim();
          const msg: IMessage = {
            msg: outputString,
            // name: "Caterine",
            name: "Ann",
            type: ServerUser.Server,
            time: new Date().toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          };

          state.msgs.push(msg);
          state.aiQuestions.push(msg);
          state.speach.botOrUser = ServerUser.None;
          state.speach.status = ISpeach.SpeachCompleted;
        }
      )
      .addCase(getQuestion.rejected, (state, action) => {
        // state.loading = false;
        state.speach.botOrUser = ServerUser.None;
        state.speach.status = ISpeach.SpeachNotStarted;
      })

      .addCase(sendAnswer.pending, (state) => {
        state.loading = true;
        state.speach.botOrUser = ServerUser.User;
        state.speach.status = ISpeach.SpeachSubmissionInprogress;
      })
      .addCase(
        sendAnswer.fulfilled,
        (
          state,
          action: PayloadAction<{ value: string; annotations: any[] }>
        ) => {
          state.loading = false;
          state.speach.botOrUser = ServerUser.User;
          state.speach.status = ISpeach.GetAINextQuestion;

          // console.log(action.payload)
        }
      )
      .addCase(sendAnswer.rejected, (state, action) => {
        state.loading = false;
        state.speach.botOrUser = ServerUser.None;
        state.speach.status = ISpeach.SpeachNotStarted;
      })

      .addCase(getFeedback.pending, (state) => {
        state.getFeedbackSubmission = false;
      })
      .addCase(
        getFeedback.fulfilled,
        (
          state,
          action: PayloadAction<{ value: string; annotations: any[] }>
        ) => {
          state.getFeedbackSubmission = true;
        }
      )
      .addCase(getFeedback.rejected, (state, action) => {
        state.getFeedbackSubmission = false;
      });

    builder
      .addCase(getQuestionV2.pending, (state) => {
        // state.loading = true;
        state.speach.botOrUser = ServerUser.Server;
        state.speach.status = ISpeach.SpeachInprogress;
      })
      .addCase(
        getQuestionV2.fulfilled,
        (state, action: PayloadAction<{ blob: Blob; aImessage: String }>) => {
          // state.loading = true;
          let outputString = action.payload.aImessage
            .replace("**Interviewer:**", "")
            .trim();
          const msg: IMessage = {
            msg: outputString,
            // name: "Caterine",
            name: "Ann",
            type: ServerUser.Server,
            time: new Date().toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
            blob: action.payload.blob,
          };

          state.msgs.push(msg);
          state.aiQuestions.push(msg);
          state.speach.botOrUser = ServerUser.None;
          state.speach.status = ISpeach.SpeachCompleted;
        }
      )
      .addCase(getQuestionV2.rejected, (state, action) => {
        // state.loading = false;
        state.speach.botOrUser = ServerUser.None;
        state.speach.status = ISpeach.SpeachNotStarted;
      });
  },
});

export const {
  sendChatMessage,
  startSession,
  pauseSession,
  resumeSession,
  endSession,
  updateTime,
  listeningStatus,
  isAISpeaking,
  resetSession,
  sessionInteruption,
  uploadedVideo,
  updateIdleTimeForUserReply,
  uploadAudioToAI,
} = chatSlice.actions;
export default chatSlice.reducer;
