import "./App.css";
// import { authStatus } from './redux/reducers/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import Header from "./components/Header";
import NotificationBar from "./components/NotificationBar";
import Loading from "./components/Loading";
import { useEffect } from "react";
import { updateUser } from "./redux/reducers/userSlice";
// import { Box, styled } from '@mui/material';

// const ContentItem = styled(Box)(({ theme }) => ({
//   paddingTop: theme.spacing(1),
// }));

function App() {
  const { authenticated } = useSelector((state: RootState) => state.usersData);
  const content = useRoutes(routes);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const res = sessionStorage.getItem("accessToken");
    if (res) {
      dispatch(
        updateUser({
          accessToken: res,
          name: "",
        })
      );
    }
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    // Function to prevent copy
    const handleCopy = (e: any) => {
      //todo enable later
      //e.preventDefault();
    };

    // Function to prevent paste
    const handlePaste = (e: any) => {
      e.preventDefault();
    };

    // Function to prevent cut
    const handleCut = (e: any) => {
      e.preventDefault();
    };

    // Function to prevent right-click (context menu)
    const handleContextMenu = (e: any) => {
      //todo enable later
      //e.preventDefault();
    };

    // Adding event listeners
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("cut", handleCut);
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // useEffect(() => {
  //   if (!authenticated) {
  //     const paths = location.pathname.split('/')
  //     paths.shift();
  //     if (paths.length > 0) {
  //       if (paths[0] !== "login") {
  //         navigate('/login')
  //       }
  //     }
  //   }

  //   return () => {
  //   };
  // }, [authenticated, location.pathname, navigate]);

  return (
    <>
      <NotificationBar />
      <Loading />
      {authenticated ? <Header /> : <></>}
      <div>{content}</div>

      {/* {state.authenticated ?
        <ContentItem >
          {content}
        </ContentItem> :
        { content }
      } */}
    </>
  );
}

export default App;
