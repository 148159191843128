/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosRequestConfig } from "axios";
import { StatusCodes } from "../utilities/apis";

const genericHeaders: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json", // 'application/x-www-form-urlencoded'
  },
  timeout: 120 * 1000,
};

const httpClient = () => {
  const token = sessionStorage.getItem("accessToken");
  if (token !== null && String(token).length > 2) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    // baseURL: "http://localhost:3000/interview",
  });
  return apiClient;
};

export const PostApi = async (
  url: string,
  params: any,
  config?: AxiosRequestConfig
) => {
  return httpClient()
    .post(url, params, {
      ...genericHeaders,
      ...config,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      if (error.status === StatusCodes.BadRequest) {
        // const erMsg = error.data as ErrorResponse;
        //Toast.show(erMsg.ErrorMessage, 'short', "bottom").subscribe()
      }

      return Promise.reject(error);
    });
};

export const GetApi = async (url: string, config?: AxiosRequestConfig) => {
  return await httpClient()
    .get(url, {
      ...genericHeaders,
      ...config,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      if (error.status === StatusCodes.BadRequest) {
        // const erMsg = error.data as ErrorResponse;
        //Toast.show(erMsg.ErrorMessage, 'short', "bottom").subscribe()
      }

      return Promise.reject(error);
    });
};

export const PutApi = async (
  url: string,
  params: any,
  config?: AxiosRequestConfig
) => {
  return await httpClient()
    .put(url, params, {
      ...genericHeaders,
      ...config,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      if (error.status === StatusCodes.BadRequest) {
        // const erMsg = error.data as ErrorResponse;
        //Toast.show(erMsg.ErrorMessage, 'short', "bottom").subscribe()
      }

      return Promise.reject(error);
    });
};

export const DeleteApi = async (url: string, config?: AxiosRequestConfig) => {
  return await httpClient()
    .delete(url, {
      ...genericHeaders,
      ...config,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      if (error.status === StatusCodes.BadRequest) {
        // const erMsg = error.data as ErrorResponse;
        //Toast.show(erMsg.ErrorMessage, 'short', "bottom").subscribe()
      }

      return Promise.reject(error);
    });
};

export const BaseAPIRequest = () => {
  const apiClient = axios.create();
  return apiClient;
};
