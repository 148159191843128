import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseAPIRequest, GetApi, PostApi } from "../../../services/api-service";
import axios, { AxiosError, AxiosHeaders } from "axios";
import { VideoConstants } from "../../../utilities/constants";
import {
  hideLoader,
  showLoader,
  showLoaderWithText,
} from "../../reducers/loaderSlice";
import { uploadedVideo } from "../../reducers/chatSlice";

interface IAIQuestion {
  threadId: string;
}

interface IAIUserAnswer {
  threadId: string | null;
  assistantId: string | null;
  answer: string;
}

export const getQuestion = createAsyncThunk(
  "interview/question",
  async (payload: IAIQuestion, { rejectWithValue, dispatch }) => {
    try {
      const response = await PostApi(`/getQuestion`, payload);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getQuestionV2 = createAsyncThunk(
  "interview/question/v2",
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await PostApi(`/nextQuestion`, payload);
      // let aImessage = "";
      // const headers = response.headers;
      // if (headers instanceof AxiosHeaders) {
      //   aImessage = String(headers.get("X-Ai-Question"));
      // }

      return {
        blob: response.data.audio,
        aImessage: response.data.text,
      };
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const sendAnswer = createAsyncThunk(
  "interview/answer",
  async (payload: IAIUserAnswer, { rejectWithValue, dispatch }) => {
    try {
      const response = await PostApi(`/sendAnswer`, payload);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getFeedback = createAsyncThunk(
  "interview/generateReport",
  async (payload: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetApi(`/generateReport`);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updateSwitchCount = createAsyncThunk(
  "interview/updateSwitchCount",
  async (payload: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetApi(`/switchCount`);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getStorageLink = createAsyncThunk(
  "interview/getStorageLink",
  async (
    payload: { file: File; isFinal: boolean },
    { rejectWithValue, dispatch }
  ) => {
    const { file, isFinal } = payload;
    try {
      if (isFinal)
        dispatch(
          showLoaderWithText({
            message:
              "Please hold on! We are processing your interview submission and saving the video. This may take a moment.",
          })
        );
      const response = await PostApi(`/getStorageLink`, {
        fileName: file.name,
        type: file.type,
        size: file.size,
      });
      return response.data;
    } catch (error) {
      if (isFinal) dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const updateRecordToS3 = createAsyncThunk(
  "interview/updateRecordToS3",
  async (
    payload: { url: string; file: File; isFinal: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { url, file } = payload;
      if (payload.isFinal) {
        dispatch(
          showLoaderWithText({
            message:
              "Please hold on! We are processing your interview submission and saving the video. This may take a moment.",
          })
        );
      }
      const formData = new FormData();
      formData.append("file", file, file.name); // Adjust the filename as needed

      // Make the Axios POST request with the FormData

      const req = axios.create();
      delete req.defaults.headers.common["Authorization"];
      const response = await req.put(url, file, {
        headers: {
          "Content-Type": VideoConstants.FileType, //"multipart/form-data",
        },
      });
      if (payload.isFinal) {
        // dispatch(hideLoader());
        dispatch(uploadedVideo());
      }
      return response.data;
    } catch (error) {
      console.error(error);
      if (payload.isFinal) {
        dispatch(hideLoader());
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updateVideoStatus = createAsyncThunk(
  "interview/updateVideoStatus",
  async (isFinal: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await PostApi(`/uploadVideoStatus`, {
        status: isFinal ? "Uploaded Completed" : "Partial Uploaded",
      });
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);
