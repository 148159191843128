import { combineReducers } from "redux";

import usersReducer from "./userSlice";
import notificationReducer from "./notificationSlice";
import loaderReducer from "./loaderSlice";
import chatReducer from "./chatSlice";
import waitingReducerf from "./waitingRoomSlice";

const rootReducer = combineReducers({
  usersData: usersReducer,
  notification: notificationReducer,
  loader: loaderReducer,
  chat: chatReducer,
  waiting: waitingReducerf
});

export default rootReducer;
