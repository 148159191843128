import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Loader = {
  open: boolean;
  message?: string;
};

type ShowLoader = Omit<Loader, "open">;

const initialState: Loader = {
  open: false,
  message: "",
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoaderWithText: (state, action: PayloadAction<ShowLoader>) => {
      state.open = true;
      state.message = action?.payload?.message
        ? action?.payload?.message
        : " Loading..";
    },
    showLoader: (state) => {
      state.open = true;
      state.message = " Loading..";
    },
    hideLoader: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

export const { showLoader, hideLoader, showLoaderWithText } =
  loaderSlice.actions;
export default loaderSlice.reducer;
