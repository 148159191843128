export const RouterLinks = {
  GLOBAL: "/",
  DEFAULT: "/",
  LOGIN: "login",
  LOGIN_NEW: "login/:userId",
  FORGOT_PASSWORD: "/forgot-password",
  NOTFOUND: "/not-found",
  WAITING_ROOM: "/waiting-room",
  ROOM: "/interview",
  THANK_YOU: "/thank-you",
  THANK_YOU_WITH_ERROR: "/thank-you-error",
  admin: {
    PATH: "admins",
    INDEX: "/admins",
    DEFAULT: "/admins/add",
    ADD: "add",
    EDIT: "edit/:adminId",
    LIST: "list",
  },
};
