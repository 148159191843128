import { FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useNavigate } from 'react-router-dom';

interface AuthLayoutProps {
  children?: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken")
    if (token == null) {
      navigate('/login')
    }
    return () => {

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children || <Outlet />}</>;
};

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default AuthLayout;
